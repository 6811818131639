@import './styles/breakpoints.css';

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-off-black: #1d1d1d;
  --color-off-white: #e8e8e8;
  --color-contrast-black: #3d3d3d;
  --color-warning-yellow: #ffcc00;

  --breakpoint-tablet: (min-width: 768px);
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}
html,
body {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
}

#root,
#canvas__container {
  height: 100%;
  width: 100%;
  will-change: background-color;
  transition: background-color 0.3s ease;

  &[data-lightmode='light'] {
    background-color: var(--color-off-white);
  }

  &[data-lightmode='dark'] {
    background-color: var(--color-off-black);
  }
}

.color-controls {
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 1.4;
}

@keyframes swiping {
  0% {
    transform: rotate(0);
  }
  12.5% {
    transform: rotate(-15deg);
  }
  37.5% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0);
  }
}

.swipeTip {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);

  &:hover .tooltipHolder {
    top: -32px;
    opacity: 1;
  }

  & svg {
    animation-name: swiping;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
}

@media (max-width: 768px) {
  .swipeTip {
    bottom: 16px;
  }
}

.tooltipHolder {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -42px;
  opacity: 0;
  white-space: nowrap;
  transition: all 0.3s ease;
}

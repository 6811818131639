@keyframes toggleVisibility {
  from {
    overflow: hidden;
  }
  to {
    overflow: visible;
  }
}

.swatchContainer {
  position: fixed;
  top: calc(50% - 20px);
  right: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--color-white);
  padding: 6px;
  border-radius: 50px;
  height: auto;
  max-height: 3.2rem;
  overflow: hidden;
  will-change: max-height, overflow;
  transition: max-height 0.3s ease, overflow 0.01s linear;

  &[data-show='true'] {
    max-height: 22rem;
    animation-name: toggleVisibility;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.31s;
    animation-duration: 0.1s;
    transition: max-height 0.3s ease;
  }

  &[data-lightmode='dark'] {
    background-color: var(--color-contrast-black);
  }
}

@media (max-width: 768px) {
  .swatchContainer {
    top: unset;
    bottom: 16px;
    right: 16px;
    flex-direction: column-reverse;
  }
}

.swatchHolder {
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12px;
}

.colorButton {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  height: 3.2rem;
  cursor: pointer;
}

.swatchItem {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  position: relative;

  &:hover .tooltipHolder {
    right: 42px;
    opacity: 1;
  }
}

.swatchItemColor {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    background-color: white;
    opacity: 0.15;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.tooltipHolder {
  position: absolute;
  top: 0;
  right: 52px;
  opacity: 0;
  white-space: nowrap;
  transition: all 0.3s ease;
}

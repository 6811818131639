.splashContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.6);
  transition: opacity 0.3s ease, height 0.01s 0.31s linear;
  overflow: hidden;

  &[data-status='closed'] {
    opacity: 0;
    height: 0;
  }
}

.splashContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 680px;

  & p {
    margin: 0 0 12px;
    font-size: 1.6rem;
    line-height: 1.4;
  }

  & small {
    color: var(--color-contrast-black);
    opacity: 0.85;
    font-size: 0.8rem;
    display: block;
    margin: 0 0 1.2rem;
  }

  & a {
    text-decoration: none;
    position: relative;
    color: inherit;
    transition: color 0.15s ease;
    will-change: color;

    &:after {
      content: '';
      position: absolute;
      top: calc(100% - 1px);
      left: -2px;
      height: 0.5px;
      width: calc(100% + 4px);
      background-color: var(--color-off-black);
      z-index: -1;
      transition: all 0.3s ease;
      will-change: top, height;
    }

    &:hover {
      color: var(--color-white);
      transition: color 0.15s 0.3s ease;

      &:after {
        height: 100%;
        top: 0;
        transition: top 0.15s ease, height 0.15s 0.3s ease;
      }
    }
  }
}

@media (max-width: 720px) {
  .splashContent {
    max-width: unset;
    width: calc(100% - 84px);
  }
}

.splashHeading {
  font-size: 36px;
  letter-spacing: 1px;
  color: var(--color-off-black);
  padding-bottom: 12px;
}

.splashCTA {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  color: var(--color-white);
  background: var(--color-off-black);
  padding: 8px;
  margin: 12px 0 0;
  cursor: pointer;
  transition: all 0.15s linear;
  border-radius: 20px;

  & svg {
    display: block;
    position: relative;
    right: 0;
    transition: right 0.3s ease;
  }

  &:hover {
    background-color: #1d1d1d;

    & svg {
      right: -4px;
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    bottom: -12px;
  }
  to {
    opacity: 1;
    bottom: 6px;
  }
}
@keyframes slideOut {
  to {
    opacity: 0;
    bottom: -12px;
  }
  from {
    opacity: 1;
    bottom: 6px;
  }
}

.warningContainer {
  position: fixed;
  z-index: 6;
  bottom: -6px;
  left: 6px;
  width: calc(100% - 36px);
  padding: 6px 12px;
  background-color: var(--color-warning-yellow);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  border-radius: 4px;
  animation-name: slideIn;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 1s;
  opacity: 0;

  &[data-state='remove'] {
    opacity: 1;
    bottom: 6px;
    animation-name: slideOut;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-delay: 0s;
    pointer-events: none;
  }

  @media (min-width: 768px) {
    display: none;
  }

  & p {
    font-size: 1rem;
    color: var(--color-off-black);
    margin: 0;
  }

  & svg {
    fill: var(--color-contrast-black);
    font-size: 3rem;
  }
}

.header {
  position: fixed;
  z-index: 2;
  padding: 24px 24px 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 100px 1fr 100px;
  width: calc(100% - 48px);
  text-align: center;
  align-items: center;

  .heading {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 24px;
    color: var(--color-off-black);
    margin: 0;
    padding: 0;
  }

  &[data-lightmode='dark'] {
    .heading {
      color: var(--color-off-white);
    }

    .audiLogo svg path {
      fill: var(--color-off-white);
    }
  }

  .audiLogo svg {
    width: 100px;
  }
}

@media (max-width: 768px) {
  .header {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .header .audiLogo svg {
    margin: 0 auto 12px;
  }
}

@media (max-width: 768px) {
  .header h1 {
    font-size: 16px;
  }
}

.lightSwitchHolder {
  display: flex;
  justify-content: flex-end;
}

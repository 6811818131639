.tooltipContainer {
  border-radius: 4px;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  position: relative;
  width: auto;
}

.tooltipMessage {
  color: var(--color-white);
  font-size: 1.2rem;
}

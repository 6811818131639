.lightswitch {
  -webkit-appearance: none;
  appearance: none;
  border: 4px solid;
  position: relative;
  padding: 2px;
  cursor: pointer;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  will-change: background-color, border-color;
  transition: all 0.3s ease;
  position: fixed;
  top: 1.6rem;
  right: 1.6rem;
  flex-direction: column;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 2rem;
    width: 100%;
    border-radius: 50%;
    will-change: left, background-color;
    transition: all 0.3s ease;
  }

  &[data-lightmode='dark'] {
    background-color: var(--color-contrast-black);
    border-color: var(--color-contrast-black);
    &:after {
      left: 0;
      background-color: var(--color-white);
    }
  }

  &[data-lightmode='light'] {
    background-color: var(--color-white);
    border-color: var(--color-white);
    &:after {
      top: calc(100% - 2rem);
      background-color: var(--color-off-black);
    }
  }

  & svg {
    width: auto;
    font-size: 1.6rem;
  }
}

@media (min-width: 768px) {
  .lightswitch {
    position: relative;
    top: unset;
    right: unset;
    flex-direction: row;
    gap: 16px;

    &:after {
      height: 100%;
      width: 2.4rem;
    }

    &[data-lightmode='dark'] {
      &:after {
        left: 0;
        top: unset;
      }
    }

    &[data-lightmode='light'] {
      &:after {
        left: calc(100% - 2.4rem);
        top: unset;
      }
    }

    & svg {
      font-size: 2rem;
    }
  }
}
